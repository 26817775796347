import { persistedExchange } from '@urql/exchange-persisted';
import { cacheExchange, createClient, fetchExchange } from '@urql/core';
import { GraphQLClient } from 'graphql-request';

import { getSdk } from '@/graphql/__generated/sdk';
import { logWarning } from '@/utils/miscUtils';

const urqlPersistedExchange = persistedExchange({
	preferGetForPersistedQueries: true,
	enforcePersistedQueries: false,
});

const urqlExchanges = [cacheExchange, urqlPersistedExchange, fetchExchange];

export const getGraphQLClientForContentful = (url: string, preview = false): ReturnType<typeof createClient> => {
	const accessToken: string = preview
		? process.env.TAG_CONTENTFUL_PREVIEW_ACCESS_TOKEN ?? process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN ?? ''
		: process.env.TAG_CONTENTFUL_ACCESS_TOKEN ?? process.env.CONTENTFUL_ACCESS_TOKEN ?? '';

	if (!accessToken) {
		logWarning('Error fetching blogs list by brand - No access token');
	}

	const headersForContentfulGraphQl = {
		Authorization: `Bearer ${accessToken}`,
		'content-type': 'application/json',
	};

	// to do
	// to update the enterprise api query to use this function itself
	// const headersForEapiGraphQl = {
	// 	'x-client-application': 'tag_dev', // or real app name with prefix tag_ e.g tag_digital_marketing
	// 	'x-client-version': 'unknown',
	// }
	// const headers = url === contentfulGraphqlURL ? headersForContentfulGraphQl : headersForEapiGraphQl

	const graphQlClient = createClient({
		url,
		exchanges: urqlExchanges,
		requestPolicy: 'network-only',
		fetchOptions: () => {
			return {
				headers: headersForContentfulGraphQl,
			};
		},
	});
	return graphQlClient;
};

export const getGraphQLClient = (url: string) => {
	const client = new GraphQLClient(url);
	return getSdk(client);
};
