// Bugsnag
export const BUGSNAG_NOTIFY_URL = 'https://bugsnag-notify.aspendental.com';
export const BUGSNAG_SESSIONS_URL = 'https://bugsnag-sessions.aspendental.com';
export const BUGSNAG_KEY = process.env.NEXT_PUBLIC_BUGSNAG_API_KEY as string;

export enum TagTheme {
	AspenDental = 'aspendental',
	AzPetVet = 'azPetVet',
	TeamTag = 'teamTag',
	WellNow = 'wellnow',
	Chapter = 'chapter',
	ClearChoice = 'clearchoice',
	ConsultClearChoice = 'consultclearchoice',
	LobbyClearChoice = 'lobbyclearchoice',
	YouWellNow = 'youwellnow',
	LivWellNow = 'livwellnow',
	Lovet = 'lovet',
}
// Add a few options for Cache Control
// CACHE_CONTROL_NO_CACHE: Used for API get requests that should not be cached
// CACHE_CONTROL_SHORT_TTL: Used for HTML Templates that should be cached for a short period of time
//                          Caching Notes:
//                           - 10 seconds for the browser, 60 seconds for the CDN, and 60 seconds for the CDN to revalidate
//                           - 0 seconds for the CDN to serve stale content if the origin is down
// Refer to: https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/Expiration.html
export const CACHE_CONTROL_NO_CACHE = 'no-cache, max-age=0, must-revalidate';
export const CACHE_CONTROL_SHORT_TTL = 'public, max-age=10, s-maxage=60, stale-while-revalidate=60, stale-if-error=0';
export const HUMANKIND_LEAD_FORM_ID = '676ae200-b351-11ef-a647-e74fd84df8f4';

// Maps HK answer id's to CC_API answer id's
export const HUMANKIND_ANSWER_ID_MAP = {
	'2sazwepJB7ZNKGP9ghS0dXemmbJ': 'bf11d2af-b3f9-11ef-a647-e74fd84df8f4', // Not missing teeth
	'2sazwjMlC9Dk7e6ejYyQ0YuA1C': 'bf11d2b0-b3f9-11ef-a647-e74fd84df8f4', // Missing 1 to 3 teeth
	'2sazwkSvJ0BXc90afPhe95C9VvE': 'bf11d2b1-b3f9-11ef-a647-e74fd84df8f4', // Missing 4 to 6 teeth
	'2sazwiiulSG7GGIdNz6dPNVusqV': 'bf11d2b2-b3f9-11ef-a647-e74fd84df8f4', // Missing 6+ teeth
	'2sazwjsdyjcxlXd16bWcJXwqFBa': 'bf11d2b8-b3f9-11ef-a647-e74fd84df8f4', // Missing all my teeth
	'2sazwlJEo2QQPOvGJkRTgfM2Zse': 'bf11d2b9-b3f9-11ef-a647-e74fd84df8f4', // Less than a year
	'2sazwhbz556PEoNpmS0hGdLWT2K': 'bf11d2ba-b3f9-11ef-a647-e74fd84df8f4', // 1-2 years
	'2sazwf59jKXJXJFF7FrykljOqsP': 'bf11d2bb-b3f9-11ef-a647-e74fd84df8f4', // 3-5 years
	'2sazwjA78Ob11mkRBhL7N4rEawW': 'bf11d2bc-b3f9-11ef-a647-e74fd84df8f4', // 6-10 years
	'2sazwkcRtCE0ToEDdgecL4hTUml': 'bf11d2c2-b3f9-11ef-a647-e74fd84df8f4', // 11 years or more
	'2sazwihc0N9hGSpGbXUS3t8vFDw': 'bf11d2c3-b3f9-11ef-a647-e74fd84df8f4', // Improve my ability to eat and speak
	'2sazwfK8SpzpYXNub5zXeY0pT0k': 'bf11d2c4-b3f9-11ef-a647-e74fd84df8f4', // Boost my confidence
	'2sazwfLJhuYhtB0O5xpYHUEekvi': 'bf11d2c5-b3f9-11ef-a647-e74fd84df8f4', // Restore my smile from an injury or accident
	'2sazwgPCcbbLhaMtWc9wE1ZSS6f': 'bf11d2c6-b3f9-11ef-a647-e74fd84df8f4', // Alleviate oral discomfort or pain
	'2sazwhcyn0uftiYajIdBQGnoTbX': 'bf11d2c7-b3f9-11ef-a647-e74fd84df8f4', // Improve my oral health
	'2sazwhZ5qGHuVNIJf2u9TLh45nG': 'a5024138-cd20-11ef-8f60-45af9e09548c', // ASAP
	'2sazwevMubYAMyG6fdhkSLd0Gj8': 'a503b9be-cd20-11ef-8f60-45af9e09548c', // Soon
	'2sazwgrRnkysquIUIwXmeaqU9Ao': 'a504d4e8-cd20-11ef-8f60-45af9e09548c', // I'm flexible
	'2sazwfkLXxAkbuRvVZiWbNhbG0c': 'a5058046-cd20-11ef-8f60-45af9e09548c', // Not sure, I am still doing my research
	'2sazwlbkcxkivIdFEDBD79VrvgP': 'bf11d2d6-b3f9-11ef-a647-e74fd84df8f4', // Yes, at ClearChoice
	'2sazwek84MJdZ94qYTHfZ0VUNdn': 'f35a2112-b3fc-11ef-a647-e74fd84df8f4', // Yes, at Aspen Dental
	'2sazwj7GAyAZlzdBgC45N9TrMNb': 'f35a213a-b3fc-11ef-a647-e74fd84df8f4', // Yes at another office
	'2sazwh4zP7L3b0XWGnYiOMEZk5u': 'f35a2144-b3fc-11ef-a647-e74fd84df8f4', // No
	'2sb0DlEZPHRm16sQJvS91LKsVeS': 'b7bf9864-bef9-11ef-8f60-45af9e09548c', // Under 660
	'2sazwfEJQ8PvEW3oOCiqCxADtL4': 'b7beb23c-bef9-11ef-8f60-45af9e09548c', // 660-699
	'2sazwiK0Z2nD266w2kpSIG7gPTv': 'b7bd45aa-bef9-11ef-8f60-45af9e09548c', // 700-739
	'2sazwkK0LvlStTge6Enplt3qaE5': 'f6dac1dc-d2c6-11ef-bdc7-b931c7524565', // 740+
	'2sb0DzmSFraBt54cLpG6BwdUPAZ': 'b7c11324-bef9-11ef-8f60-45af9e09548c', // I don't know or prefer not to answer
	'2sazweqrM7cHhSnhU6q1rdHQ8vF': 'b7ba836a-bef9-11ef-8f60-45af9e09548c', // I intend to self-pay
};

const endpointOverride = process.env.CONTENTFUL_GRAPHQL_ENDPOINT;
const contentfulEnvironmentSuffix = process.env.CONTENTFUL_ENVIRONMENT_ID
	? `/environments/${process.env.CONTENTFUL_ENVIRONMENT_ID}`
	: '';
const productionEndpoint = 'https://graphql.contentful.com/content/v1/spaces';

export const contentfulGraphQLURL = `${endpointOverride || productionEndpoint}/${
	process.env.CONTENTFUL_SPACE_ID
}${contentfulEnvironmentSuffix}`;
