import { HUMANKIND_ANSWER_ID_MAP } from '@src/constants';
import { useEffect } from 'react';

export const humankindHandler = async (event: CustomEvent) => {
	if (event.type === 'hk-survey-completed') {
		const {
			firstName,
			lastName,
			email,
			phoneNumber,
			zipCode,
		}: { firstName: string; lastName: string; email: string; phoneNumber: string; zipCode: string } = event.detail;
		const hkDataString = localStorage.getItem('hk-customer');
		const hkData = JSON.parse(hkDataString ?? '');

		// Humankind specific re-formatting phone number
		function formatPhoneNumber(input: string | number): string {
			// Convert input to a string and strip out non-numeric characters
			const cleaned = input.toString().replace(/\D/g, '');

			// Extract area code, first 3 digits, and last 4 digits
			const areaCode = cleaned.slice(0, 3);
			const firstPart = cleaned.slice(3, 6);
			const secondPart = cleaned.slice(6);

			// Return the formatted phone number
			return `(${areaCode}) ${firstPart}-${secondPart}`;
		}

		const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

		const answerIds: { [key: string]: number | number[] } = {};

		hkData.survey.forEach((question: any) => {
			// If `question.answer` is an array, handle it as multiple answers
			if (Array.isArray(question.answer)) {
				const matchedOptions = question.options
					.filter((option: any) => question.answer.includes(option.data)) // Filter to find all matching options
					.map((option: any) => option.id); // Extract their IDs
				if (matchedOptions.length > 0) {
					answerIds[question.question] = matchedOptions.map((option) => HUMANKIND_ANSWER_ID_MAP[option]); // Assign the array of IDs
				}
			} else {
				// Handle single answer case
				const matchedOption = question.options.find((option: any) => option.data === question.answer);
				if (matchedOption) {
					answerIds[question.question] = HUMANKIND_ANSWER_ID_MAP[matchedOption.id]; // Replace the answer with the matching option's ID
				}
			}
		});

		const requestData = {
			subject: 'leads',
			answers: {
				first_name: firstName,
				last_name: lastName,
				email: email,
				phone: formattedPhoneNumber,
				zip_code: zipCode,
				hk_missing_teeth: answerIds['Are You Currently Missing Any Teeth?'],
				hk_how_long_missing: answerIds['How Long Have You Been Missing Teeth?'],
				hk_motivators: answerIds['What is Your Biggest Motivator for Moving Forward with Dental Implants?'],
				quiz_treatment_exigency: answerIds['When Are You Wanting to Start Your Dental Implant Treatment?'],
				hk_consultation: answerIds['Have You Ever Had a Dental Implant Consultation?'],
				current_credit_score:
					answerIds[
						'To Help Us Ensure You Receive the Most Relevant Offers, Treatment and Related Information, Please Choose the Option That Best Describes Your Credit Score.'
					],
			},
		};

		try {
			const response = await fetch('/api/clear-choice/submit-humankind-lead/', {
				method: 'POST',
				body: JSON.stringify(requestData),
			});
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
		} catch (error) {
			console.error('Error submitting form:', error);
		}
	}
};

export const useHumankind = (brandName: string) => {
	useEffect(() => {
		const isClearChoice = brandName === 'ClearChoice';
		if (isClearChoice) {
			document.body.addEventListener('hk-survey-completed', humankindHandler as unknown as EventListener);
		}
		return () => {
			if (isClearChoice) {
				document.body.removeEventListener('hk-survey-completed', humankindHandler as unknown as EventListener);
			}
		};
	}, [brandName]);
};
